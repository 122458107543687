<template>
  <div class="hello">
    <StreamBarcodeReader
      @decode="(a, b, c) => onDecode(a, b, c)"
      @loaded="() => onLoaded()"
      v-show="!text" ></StreamBarcodeReader>
    
    <div id="output">
                  
                  <div> <span id="scanned-QR" style="color: bold;">
                  <h3 v-if="url">
                   <a target="blank" :href="url">{{url }}</a>
                  </h3>
                  <h3 v-if="!url">{{ text || "Scanning....." }}</h3>
                  
                  
                  </span></div>
                  <div id=""  v-show="text">
                    <button class="btn btn-dark btn-lg" v-on:click="scanagain()">Scan again</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button class="btn btn-dark btn-lg" v-on:click="copy()">{{ coopyText }}</button>
                     <input type="text" v-show="false" id="hiddenInput" v-model="text" />
                  </div>
    </div>
  </div>
  
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  name: "HelloWorld",
  components: {
    StreamBarcodeReader,
  },
  data() {
    return {
      text: "",
      id: null,
      coopyText:"copy to clipboard",
      url:""
    };
  },
  props: {
    msg: String,
  },
  methods: {
    onDecode(a, b, c) {
      console.log(a, b, c);
      this.text = a;
      
      if(this.validURL(a))
      {
        this.url=a;     
            
      }
      else{
        this.url="";
      }
      if (this.id) clearTimeout(this.id);
      this.id = setTimeout(() => {
        if (this.text === a) {
         // this.text = "";
        }
      }, 5000);
    },
    onLoaded() {
      console.log("load");
    },
    scanagain(){
        
        this.text = "";
        this.url="";
        this.coopyText ="copy to clipboard";
    },
    copy()
    {
        this.setClipboard(this.text);
        this.coopyText ="copied!";
        
    },
    setClipboard(value) {
        var tempInput = document.createElement("input");
        tempInput.style = "position: absolute; left: -1000px; top: -1000px";
        tempInput.value = value;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
    },
     validURL(str) {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test(str);
     }
  },
};
</script>
<style scoped>
</style>
